import '../styles/global.scss';
import React from 'react';
import { Helmet } from 'react-helmet';

const Wrapper = ({ children, ...props }) => (
  <div {...props}>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/uzp4vrz.css" />
    </Helmet>
    {children}
  </div>
);
export default Wrapper;
